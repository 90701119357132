







































































































































import {Component} from 'vue-property-decorator';

import SelectorSite from '@/site/components/SelectorSite.vue';

import AffluenceModal from './AffluenceModal.vue';
import BaseService from './BaseService.vue';

@Component({components: {SelectorSite, AffluenceModal}})
export default class AppBar extends BaseService {}
