import {PRODUCT_TYPE} from '../constants';
import {Day, Menu, MenuState, Picto, Product, Service, Zone} from '../interfaces';
import {hasMenus, reduceDays} from '../tools';

export const getters = {
  currentMenu: (state: MenuState): Menu | null => state.currentMenu,
  currentProductTypes: (state: MenuState): Picto[] => state.currentProductTypes,
  day: (state: MenuState): Day | null => state.selectedDay,
  days: (state: MenuState): Day[] => reduceDays(state.menus),
  error: (state: MenuState): boolean => state.error,
  formulaZones: (state: MenuState): Zone[] => state.zones.filter((zone) => zone.formula),
  formulaPrice:
    (state: MenuState) =>
    (code: string): number | null =>
      getters
        .currentMenu(state)
        ?.products?.filter(
          (product) => product.type === PRODUCT_TYPE.FOR && product.zoneCode === code
        )[0].price || null,
  formulaProducts:
    (state: MenuState) =>
    (code: string): Product[] =>
      getters
        .currentMenu(state)
        ?.products?.filter(
          (product) => product.type !== PRODUCT_TYPE.FOR && product.zoneCode === code
        ) || [],
  hasProducts:
    (state: MenuState) =>
    (service: Service): boolean =>
      hasMenus(state.menus, state.selectedDay, service),
  loadingMenu: (state: MenuState): boolean => state.loading,
  menus: (state: MenuState): Menu[] => state.menus,
  menusAvailable: (state: MenuState): boolean => state.menus.length > 0,
  products: (state: MenuState): Product[] =>
    getters
      .currentMenu(state)
      ?.products?.filter(
        (product) =>
          product.type !== PRODUCT_TYPE.FOR &&
          !getters.formulaZones(state).some((zone) => zone.code === product.zoneCode)
      )
      .sort((a, b) => (a.zoneCode < b.zoneCode ? -1 : 1)) || [],
  selectedProductTypeName: (state: MenuState): string => state.selectedProductTypeName,
  service: (state: MenuState): Service | null => state.selectedService,
  services: (state: MenuState): Service[] =>
    state.services
      .filter((service) => getters.hasProducts(state)(service))
      .sort((a, b) => (a.startAt < b.startAt ? -1 : 1)),
  zone:
    (state: MenuState) =>
    (code: string): Zone | null =>
      state.zones.find((zone) => zone.code === code) || null,
  zones: (state: MenuState): Zone[] => state.zones,
};
