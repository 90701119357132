import {Theme} from '../interfaces';

export const THEME: Theme = {
  primary: '#0575E6',
  secondary: '#000000',
  tertiary: '#E6F1FC',
  background: '#F8F8F8',
  captionText: '#A4A4A4',
  accent: '#82B1FF',
  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107',
  anchor: '#F4BF12',
  affluenceDirect: '#E74545',
  affluenceAvg: '#669DF6',
};
