
































































































import {Capacitor} from '@capacitor/core';
import {Component, Vue, Watch} from 'vue-property-decorator';
import {Getter} from 'vuex-class';

import AppBar from '@/core/components/AppBar.vue';
import Footer from '@/core/components/Footer.vue';

import {mdiSvg, nameRoutes} from './core';
import AppleMessagePWA from './core/components/AppleMessagePWA.vue';
import Banner from './core/components/Banner.vue';
import BottomBar from './core/components/BottomBar.vue';
import {Day} from './menu/interfaces';

@Component({
  components: {
    AppBar,
    Banner,
    Footer,
    BottomBar,
    AppleMessagePWA,
  },
})
export default class App extends Vue {
  @Getter('error', {namespace: 'site'}) error!: boolean;

  @Getter('name', {namespace: 'site'}) siteName!: string;

  @Getter('isOpenSelector', {namespace: 'site'}) isOpenSelector!: boolean;

  @Getter('days', {namespace: 'menu'}) days!: Day[];

  @Getter('slug', {namespace: 'site'}) slug!: string;

  routes = nameRoutes;

  svgIcons = mdiSvg;

  worker: Worker | null = null;

  refreshing = false;

  isOffline = false;

  isMobileApp = false;

  updateExists = false;

  @Watch('$store.state.site.name')
  private updateTitle() {
    if (this.siteName) {
      document.title = this.siteName;
      const manifestElement = document.getElementById('manifest');
      const manifestString = JSON.stringify({
        id: this.slug,
        name: this.siteName,
        short_name: this.siteName,
        start_url: `${window.location.origin}${this.$route.path}`,
        display: 'standalone',
        icons: [
          {
            src: `${window.location.origin}/android-chrome-192x192.png`,
            sizes: '192x192',
            type: 'image/png',
          },
          {
            src: `${window.location.origin}/android-chrome-512x512.png`,
            sizes: '512x512',
            type: 'image/png',
          },
        ],
        theme_color: '#0575E6',
        scope: window.location.origin,
      });
      if (manifestElement) {
        manifestElement.setAttribute(
          'href',
          `data:application/json;charset=utf-8,${encodeURIComponent(manifestString)}`
        );
      }
    }
  }

  @Watch('$store.state.site.error')
  private onError() {
    if (this.error && this.$route.name !== this.routes.notFound) {
      document.title = 'Page introuvable';
      this.$router.replace('/non-trouve');
    }
  }

  created(): void {
    // Listen for swUpdated event and display refresh snackbar as required.
    document.addEventListener('swUpdated', this.showRefreshUI, {once: true});
    document.addEventListener('swOffline', this.showOfflineUI, {once: true});

    // Refresh all open app tabs when a new service worker is installed.
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener(
        // triggered by registration.claim
        'controllerchange',
        () => {
          if (this.refreshing) return;
          this.refreshing = true;
          console.log('controllerchange triggered, -> auto refresh!!');
          window.location.reload();
        }
      );
    }
  }

  mounted(): void {
    document.title = 'Impactiv';
    if (Capacitor.getPlatform() === 'ios') {
      this.isMobileApp = true;
    } else if (Capacitor.getPlatform() === 'android') {
      this.isMobileApp = true;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  showRefreshUI(e: any): void {
    // Display a button inviting the user to refresh/reload the app due
    // to an app update being available.
    // The new service worker is installed, but not yet active.
    // Store the ServiceWorkerRegistration instance for later use.

    if (e.detail) {
      this.worker = e.detail;
      this.updateExists = true;
      // console.log('Showing refresh button.');
      // console.log(this.worker, 'worker');
    } else {
      console.warn('No worker data found!');
    }
  }

  showOfflineUI(): void {
    this.isOffline = true;
  }

  refreshApp(): void {
    this.updateExists = false;
    if (!this.worker) {
      console.warn('No worker data found when trying to refresh!');
    } else this.worker.postMessage({type: 'SKIP_WAITING'});
  }
}
