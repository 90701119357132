import spacetime from 'spacetime';

import {formatShortDate, isBeforeDate, isEqualDate, isToday, tzParis} from '@/core/tools';

import {Day, Menu} from '../interfaces';

export function reduceDays(menus: Menu[]): Day[] {
  return menus
    .reduce<Day[]>((days, menu) => {
      if (
        !days.find((day) => isEqualDate(menu.date, day.date)) &&
        (isBeforeDate(spacetime.now(tzParis).toLocalDate().toISOString(), menu.date) ||
          isToday(menu.date))
      ) {
        days.push({date: menu.date, formattedShortDate: formatShortDate(menu.date)});
      }
      return days;
    }, [])
    .sort((a, b) => (isBeforeDate(a.date, b.date) ? -1 : 1))
    .slice(0, 5);
}

export function getNextDay(menus: Menu[]): Day {
  return reduceDays(menus).reduce<Day>(
    (currentDay, nextDay) =>
      isBeforeDate(currentDay.date, nextDay.date) && !isToday(currentDay.date)
        ? currentDay
        : nextDay,
    {
      date: new Date().toISOString(),
      formattedShortDate: formatShortDate(new Date().toISOString()),
    }
  );
}
