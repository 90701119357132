import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{staticClass:"ma-0 fill-height",attrs:{"persistent":_vm.$vuetify.breakpoint.smAndDown,"fullscreen":_vm.$vuetify.breakpoint.smAndDown,"max-width":"500px"},on:{"click:outside":function($event){return _vm.$router.back()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VSheet,{staticClass:"fill-width",attrs:{"outlined":"","color":"white","rounded":""}},[_c(VHover,{attrs:{"close-delay":"30","open-delay":"30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,_vm._g({staticClass:"d-flex justify-space-between flex-column pa-3",class:{'on-hover': hover},attrs:{"rounded":"","height":"100%","elevation":hover ? 4 : 0},on:{"click":function($event){$event.stopPropagation();return _vm.updateRoute(true, _vm.product)}}},on),[_c('div',{staticClass:"d-flex justify-space-between flex-grow-1 align-start"},[_c('div',{staticClass:"d-flex flex-column fill-height pr-4"},[_c('span',{staticClass:"caption mr-2 font-weight-medium d-flex align-center flex-grow-0"},[_c(VIcon,{staticClass:"pr-1",attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.svgIcons.mapMarker)+" ")]),(_vm.zone(_vm.product.zoneCode))?_c('span',{staticClass:"dishDetails__location"},[_vm._v(" "+_vm._s(_vm.zone(_vm.product.zoneCode).name))]):_vm._e()],1),_c('h3',{staticClass:"pa-0 pt-1 pb-4 dishDetails__name text-h3 font-weight-bold align-self-start flex-grow-1"},[_vm._v(" "+_vm._s(_vm.product.name)+" ")]),(_vm.getPrice(_vm.product))?_c('h3',{staticClass:"text-h3 font-weight-medium dishDetails__price flex-grow-0"},[_vm._v(" "+_vm._s(_vm.getPrice(_vm.product))+" ")]):_vm._e()]),_c(VBtn,_vm._g({staticClass:"text-none",attrs:{"elevation":"0","outlined":"","x-small":"","color":"primary","height":"100%","max-width":"0.75rem"},on:{"click":function($event){$event.stopPropagation();return _vm.updateRoute(true, _vm.product)}}},on),[_c(VIcon,{attrs:{"medium":""}},[_vm._v(" "+_vm._s(_vm.svgIcons.chevronRight)+" ")])],1)],1)])]}}],null,true)})],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ModalProduct',{attrs:{"product":_vm.product},on:{"close-dialog":function($event){_vm.dialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }