export const nameRoutes = {
  notFound: 'notFound',
  home: 'home',
  menu: 'menu',
  articles: 'articles',
  badge: 'badge',
  clickAndCollect: 'clickAndCollect',
  roomService: 'roomService',
};

export function formatSlug(slug: string): {portal?: string; slug?: string} {
  if (slug.charAt(0).toLocaleLowerCase() === 'p') {
    const formattedSlug = slug.split('-');
    return {
      slug: formattedSlug[1] ? formattedSlug[1].toLocaleLowerCase() : undefined,
      portal: `${formattedSlug[0].toLocaleLowerCase()}-`,
    };
  }
  return {slug: slug.toLocaleLowerCase()};
}
