import {MenuState} from '../interfaces';

export const state: MenuState = {
  currentMenu: null,
  loading: false,
  menus: [],
  selectedDay: null,
  selectedService: null,
  services: [],
  zones: [],
  error: false,
  currentProductTypes: [],
  selectedProductTypeName: '',
};
